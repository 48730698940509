import Vue from 'vue'
import App from './App.vue'
import * as CodeMirror from 'codemirror';
import { BootstrapVue } from 'bootstrap-vue';

Vue.config.productionTip = false

Vue.prototype.$CodeMirror = CodeMirror;

Vue.use(BootstrapVue);



import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';


new Vue({
  render: h => h(App),
}).$mount('#app')
