<template>
  <div id="app">
    <AppHeader />
    <div id="content" class="content"></div>
  </div>
</template>

<script>
import AppHeader from "./components/Three_Viewport.vue";


export default {
  name: 'App',
  components: {
    
    AppHeader, 
  }
  
}
</script>

<style>

</style>
